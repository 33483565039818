
import imports from "../services/imports";
import { downloadAsBlob } from '@/util/file';
import ImportSection from "@/components/modals/ImportSection";
import ImportResult from "@/components/modals/ImportResult";
import { mapGetters } from 'vuex';

export default {
  components: {
    ImportSection,
    ImportResult
  },
  // props: {
  //   openModel: Boolean,
  // },
  data() {
    return {
      loading: false,
      selectedKPI: 'sales',
      importData: null,
      options: [
        {
          label: this.$store.state.user.translate.sales || "Sales",
          value: 'sales'
        },
        {
          label: this.$store.state.user.translate.sales_target || "Sales Target",
          value: 'sales-target'
        },
        {
          label: this.$store.state.user.translate.traffic || "Traffic",
          value: 'traffic'
        },
        {
          label: this.$store.state.user.translate.staff_hours || "Staff Hours",
          value: 'staff-hours'
        }
      ],
      selectedSalesMappingTemplate: null,
      salesMappingTemplates: [],
      dataAccessibleColumns: [
        {
          name: "no",
          align: "center",
          label: "#",
          field: "no",
          sortable: true,
        },
        {
          name: "fileName",
          align: "left",
          label: this.$store.state.user.translate.name,
          field: "fileName",
          sortable: true,
        },
        { name: "status", label: this.$store.state.user.translate.status, field: "status" },
        { name: "action", label: this.$store.state.user.translate.actions, field: "action" },
      ],
    }
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList'])
  },
  async mounted() {
    await this.fetchSalesMappingTemplate();
  },

  methods: {
    async fetchSalesMappingTemplate() {
      imports.getTrafficMapping(this.selectedKPI).then((response) => {
        this.salesMappingTemplates = response.content
        this.selectedSalesMappingTemplate = response.content[0]
      })
    },
    addStore() {

    },
    updateSelectedKPI(kpi) {
      this.selectedKPI = kpi.value
      this.fetchSalesMappingTemplate()
    },
    async downloadTemplate() {
      const salesResponse = "Store Code,Transaction Date,Transaction Hour,Total Transactions,Items Sold,Sales Amount\nxxx,YYYYMMDD,hhmm,1111,1111,1111";
      const salesTargetResponse = "Store Code,Date,Sales Target\nxxx,YYYYMMDD,1111";
      const trafficResponse = "Store Code,Date,Time,Entrance,IN_OUT,Traffic\nxxx,YYYYMMDD,hhmm,xxx,xxx,xxx";
      const staffHoursResponse = "Store Code,Staff Hour Date,Staff Hour Time,Staff In Store\nxxx,YYYYMMDD,hhmm,xxx";

      const selectedKPI = this.selectedKPI + '-template.csv';
      const fileType = 'text/csv';

      if(this.selectedKPI == 'sales')
        downloadAsBlob(salesResponse, fileType, selectedKPI);

      else if(this.selectedKPI == 'sales-target')
        downloadAsBlob(salesTargetResponse, fileType, selectedKPI);

      else if(this.selectedKPI == 'traffic')
        downloadAsBlob(trafficResponse, fileType, selectedKPI);

      else if(this.selectedKPI == 'staff-hours')
        downloadAsBlob(staffHoursResponse, fileType, selectedKPI);
    },

    downloadAsBlob(raw, type, filename) {
      const blob = new Blob([raw], { type });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async handleUploadold(file) {
      this.loading = true
      await imports.importReportData(file,
        this.selectedSalesMappingTemplate.id,
        this.selectedKPI).then((response) => {
          this.loading = false;
          this.importData = response;
          this.$q.notify({
            message: 'Imported Successfully',
            color: 'green'
          })
        }).catch((error) => {
          this.importData = error?.response
          if (error.response.message) {
            this.$q.notify({
              message: error.response.message,
              color: 'red'
            })
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: 'red'
            })
          }
          this.loading = false
        })
    },
    async handleUpload(file) {
      try {
        this.loading = true;
        const response = await imports.importReportData(
          file,
        //  this.selectedSalesMappingTemplate.id,
          this.selectedKPI,
          this.getStoreCodesList
        );
        this.loading = false;
        this.importData = response;
        if (response == "" || ((response.successCount?response.successCount:0) >0) || response?.status==200) {
          this.$q.notify({
            message: 'Imported Successfully',
            color: 'green'
          });
        } else {
          this.$q.notify({
            message: response.message,
            color: 'red'
          });
        }
      } catch (error) {
        this.loading = false;
        this.importData = error?.response?.data || error;
        const errorMessage = this.importData || this.$store.state.user.translate.something_went_wrong;
        this.$q.notify({
          message: errorMessage,
          color: 'red'
        });
      }
    },
  }
};
